import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

const LegalNotice = props => {

    const lang = props.pageContext.langKey;

    return (
        <Layout lang={lang} location={props.location}>
            <Seo title='Cookies Policy' meta='Our cookies policy' slug='cookies-policy' />
            <Helmet>
                <meta property="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="legal">
                <div className="container">
                    <h1 className="legal__heading">
                        Cookie Policy
                    </h1>
                    <div className="legal__section">
                        <h2 className="legal__subheading">
                            1. What are cookies?
                        </h2>
                        <p>
                        The Valudio GmbH website (hereinafter the Web) uses cookies, which are small text files sent to a browser through a web server to record the activities of the user on a given website. The primary purpose of cookies is to provide the user with faster access to selected services. In addition, cookies personalize the services
                        offered by the Web, providing and offering each user information that may be of his or her interest based on how the user uses the Web’s services.
                        </p>
                        <p>
                        The Web uses cookies to personalize and facilitate the browsing experience as much as possible. Cookies are only associated with an anonymous user and his or her computer and do not provide references that allow the user's personal data to be deduced. Users can configure their browsers to notify and reject the installation of cookies that is sent by the Web without affecting the user's ability to access the contents of the website. It is important to note, however, that the quality of operation of the Web may decrease is cookies are not enabled.
                        </p>
                        <p>
                        Registered users, who register on the Web or have logged in to the Web, can benefit from more personalized service that is oriented to their profile, since the data stored in the cookies is combined with the personal data used at the time of registration. Registered or logged in users expressly authorize the use of this information for the indicated purpose, without prejudice to their right to reject or disable the use of cookies.
                        </p>
                        <p>
                        The Web therefore is able to know all of the services requested by the user, so that the company behind the Web will be able to optimize or offer appropriate information that matches the tastes and preferences of each user.
                        </p>
                    </div>
                    <div className="legal__section">
                        <h2 className="legal__subheading">
                        2. What type of cookies does this Web use?
                        </h2>
                        <p>
                        Cookies are differentiated based on how long they remain, and can be divided into the following types:
                        </p>
                        <h3>
                        Session Cookies
                        </h3>
                        <p>
                        Session cookies expire when the user closes the browser.
                        </p>
                        <h3>
                        Persistent Cookies
                        </h3>
                        <p>
                        Persistent cookies expire when the purpose for which they are used is fulfilled (for example, they remain as long as a user needs to be identified in a service), or when they are deleted manually.
                        </p>
                        <p>
                        Additionally, depending upon their purpose, cookies can be classified as follows:
                        </p>
                        <h3>
                        Performance Cookies
                        </h3>
                        <p>
                        Performance cookies remember user preferences for the tools contained within the services, so the user does not need to reconfigure the service each time he or she visits the Web. Video and audio volume settings are an example.
                        </p>
                        <h3>
                        Geo-Tracking Cookies
                        </h3>
                        <p>
                        These cookies are used to find out what country a user is in when requesting a service. This cookie is completely anonymous and is only used to help direct content relevant to the user’s location.
                        </p>
                        <h3>
                        Log Cookies
                        </h3>
                        <p>
                        Log cookies are generated once the user has registered or has opened a session, and are used to identify the user in the services for the following purposes:
                        </p>
                        <p>
                        – To keep the user identified so that, even if the service is closed, the browser or computer may re-enter the service later, without having to re-identify him-or herself. This functionality can be deleted if the user clicks "log out," which deletes the cookie and requires a new log in the next time the service is requested by the same user.
                        </p>
                        <p>
                        – To check if the user is authorized to access certain services, for example, to participate in a contest.
                        </p>
                        <h3>
                        Analytic Cookies
                        </h3>
                        <p>
                        Each time a user visits a service, a tool from an external provider generates an analytical cookie on the user's computer. The cookie that is generated will be used in subsequent visits to utilize the Web’s services in order to anonymously identify the visitor.
                        </p>
                        <p>
                        The main objectives that are pursued are:
                        </p>
                        <p>
                        – To allow anonymous identification of users through the cookie (which identifies browsers and devices, not people) and therefore to provide an approximate count of the number of visitors and the time they tend to visit.
                        </p>
                        <p>
                        – To anonymously identify the most visited content and be able to make it more attractive to users.
                        </p>
                        <p>
                        – To know if the user that is accessing the Web is a new or returning visitor to the Web.
                        </p>
                        <p>
                        Important: Unless the user decides to register for a Web service, the cookie will never be associated with any identifiable personal data. The cookies will only be used for statistical purposes that help to optimize the experience of users on the site.
                        </p>
                        <h3>
                        Behavioral Advertising Cookies
                        </h3>
                        <p>
                        Behavioral advertising cookies allow the company behind the Web to extend the advertisements shown to each anonymous user of the Web services. This cookie stores the duration or frequency of the display of advertising positions, the user’s interaction with them, browsing patterns and/or behavior of the user in order to help form a profile of their advertising interests. In this way, the cookies allow advertising that is related to the interests of the user to be displayed.
                        </p>
                        <h3>
                        Third-Party Advertising Cookies
                        </h3>
                        <p>
                        In addition to the advertising managed by the Web in its services, the Web offers its advertisers the option of serving ads through third parties ("AdServers"). In this way, the third parties can store cookies sent from the Web services from the users' browsers, as well as access the data stored in them.
                        </p>
                    </div>
                    <div className="legal__section">
                        <h2 className="legal__subheading">
                        3. What cookies do the Valudio website use?
                        </h2>
                        <p>
                        The only cookies we use on our website are:
                        </p>
                        <h3>
                        First-Party Cookies
                        </h3>
                        <p>
                        Cookies that are sent to the user's computer from a computer or domain managed by the editor and from which the service requested by the user is provided.
                        </p>
                        <h3>
                        Third-Party Computer
                        </h3>
                        <p>
                        Cookies that are sent to the user's computer from a computer or domain that is not managed by the editor, but by another entity that processes the data obtained through the cookies.
                        </p>
                    </div>
                    <div className="legal__section">
                        <h2 className="legal__subheading">
                        4. How can a user refuse cookies?
                        </h2>
                        <p>
                        It is usually possible to stop accepting browser cookies, or to stop accepting cookies from a particular service.
                        </p>
                        <p>
                        All modern browsers allow users to change their cookie settings. These settings are usually found under ̈options ̈ or ̈preferences ̈ in the browser menu. Browsers and email managers can also be configured, and free plug-ins can be installed to prevent bugs from being downloaded when opening an email.
                        </p>
                        <p>
                        Guidance for users on how to access the cookie configuration menu and, where appropriate, how to enable private browsing is available in all of the main browsers:
                        </p>
                        <ul>
                            <li>
                            Internet Explorer Tools{`>`}Internet options{`>`}Privacy{`>`}settings. <br />
                            For more information, see Microsoft Support or Browser Help.
                            </li>
                            <li>
                            Microsoft Edge Settings and more{`>`}settings{`>`}Clear Browsing Data
                            </li>
                            <li>
                            Firefox Tools{`>`}Options{`>`}Privacy{`>`}History{`>`}custom settings
                            </li>
                            <li>
                            Chrome Settings{`>`}Advanced settings{`>`}Privacy and security{`>`}Delete browsing data
                            </li>
                            <li>
                            Safari Preferences{`>`}security.
                            </li>
                            <li>
                            For more information, see Apple Support or Browser Help.
                            </li>
                        </ul>
                    </div>
                    <div className="legal__section">
                        <h2 className="legal__subheading">
                        5. Can this Cookies Policy be changed?
                        </h2>
                        <p>
                        The Web may modify this Cookies Policy to meet legislative, regulatory requirements or for the purpose of adapting this policy to mandates by the Spanish Data Protection Supervisory Authority. For this reason it is advised that users visit the Web’s Cookies Policy periodically.
                        </p>
                        <p>
                        When significant changes are made to this Cookies Policy, registered users will be notified either via the website or by e-mail.
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default LegalNotice;